@font-face {
  font-family: 'Garamond Roman';
  src: url('../../common/font/GARMNDR.ttf') format('truetype');
}

body {
  font-family: Helvetica, 'Neue Haas Grotesk', sans-self;
}

// ----- ビデオ -----
.video {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 20;
  position: relative;
  top: 0;
  left: 0;

  video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
}

// ----- メインビジュアル -----
.mainVisual {
  background-image: url('../img/main/default.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  transition: .4s;
  position: relative;
}

.mainVisual__img {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: 2;
  opacity: 0;
  transition: .3s;
}

.mainVisual__img--active {
  opacity: 1;
}

.mainMenu {
  padding-top: 60px;
  position: relative;
  z-index: 10;

  @media only screen and (max-width: 767px) {
    padding-top: 0px;
  }

  > li {
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
      position: relative;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      &:first-child {
        padding-top: 10px;
      }
    }

    + li {
      border-top: 1px solid rgba(255, 255, 255, 0.5);

      @media only screen and (max-width: 767px) {
        border-top: 2px solid #fff;
      }
    }

    a {
      color: #fff;
      opacity: 0.6;
      display: block;
      text-align: center;
      font-size: 64px;
      transition: .2s;
      // height: calc((100vh - 60px) / 6); JSで処理
      // line-height: calc((100vh - 60px) / 6); JSで処理

      &:hover {
        opacity: 1;
      }

      @media only screen and (max-width: 767px) {
        font-size: 32px;
        opacity: 1;

        span {
          position: absolute;
          height: 32px;
          margin: auto;
          right: 0;
          left: 0;
          bottom: 14px;
          line-height: 32px !important;
        }
      }
    }
  }
}

// ----- カテゴリー -----
.category__ele {
  height: 100vh;
  position: relative;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;

  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
  }
}

.category__img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.category__vertical {
  position: absolute;
  font-weight: bold;
  font-size: 140px;
  left: -22px;
  bottom: 0;
  writing-mode: vertical-rl;

  @media only screen and (max-width: 767px) {
    left: auto;
    right: 12px;
    bottom: 40px;
    transform: rotate(180deg);
    font-size: 64px;
    font-weight: normal;
  }
}

.category__bottom {
  position: absolute;
  right: 20px;
  bottom: 20px;

  @media only screen and (max-width: 767px) {
    right: 12px;
    bottom: 40px;
  }
}

.category__head {
  text-align: right;
  font-size: 83px;
  margin-bottom: 11px;

  @media only screen and (max-width: 767px) {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 32px;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    right: 250px;
  }
}

.category__text {
  line-height: 1.2;
  font-size: 20px;

  @media only screen and (max-width: 767px) {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 12px;
    line-height: 24px;
    position: absolute;
    bottom: 0;
    left: -221px;
    height: 80vh;
  }
}


// ----- スライダー -----
.swiper {
  padding: 198px 0;
  width: 100%;

  @media only screen and (max-width: 1020px) {
    padding: 120px 0;
  }

  @media only screen and (max-width: 767px) {
    padding: 72px 0;
  }
}

.swiper-slide {
  width: 778px !important;
  text-align: center;

  @media only screen and (max-width: 1020px) {
    width: 540px !important;
  }

  @media only screen and (max-width: 767px) {
    width: 272px !important;
  }

  img {
    width: 620px;

    @media only screen and (max-width: 1020px) {
      width: 450px;
    }

    @media only screen and (max-width: 767px) {
      width: 240px;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  width: 14px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: .5;
  margin-top: -7px;

  @media only screen and (max-width: 767px) {
    width: 8px;
    height: 13px;
  }

  &::after {
    content: none;
  }
}

.swiper-button-prev {
  background-image: url('../img/slider/prev.svg');
  right: calc(50% + 382px);
  left: auto;

  @media only screen and (max-width: 1020px) {
    right: calc(50% + 264px);
  }

  @media only screen and (max-width: 767px) {
    right: calc(50% + 132px);
  }
}

.swiper-button-next {
  background-image: url('../img/slider/next.svg');
  right: auto;
  left: calc(50% + 382px);

  @media only screen and (max-width: 1020px) {
    left: calc(50% + 264px);
  }

  @media only screen and (max-width: 767px) {
    left: calc(50% + 132px);
  }
}

.swiper-button-disabled {
  opacity: 0 !important;
}

// ----- SNS -----
.bg {
  background-color: #f5f5f5;
  padding: 113px 0 106px;

  @media only screen and (max-width: 767px) {
    padding: 64px 0 60px;
  }
}

.logo {
  width: 410px;
  display: block;
  margin: 0 auto 24px;

  @media only screen and (max-width: 767px) {
    width: 340px;
    margin-bottom: 0px;
  }
}

.sns {
  display: flex;
  justify-content: center;
}

.sns__ele {
  width: 100px;

  @media only screen and (max-width: 767px) {
    width: 46px;
  }

  a {
    display: block;
    text-align: center;
    padding: 20px 0;
    font-size: 32px;

    &:hover {
      opacity: .5;
    }

    @media only screen and (max-width: 767px) {
      font-size: 16px;
      padding-top: 8px;
    }
  }
}

// ----- ブランド -----
.brandsWrap {
  padding-top: 75px;
  padding-bottom: 80px;

  @media only screen and (max-width: 767px) {
    padding-top: 66px;
    padding-bottom: 40px;
  }
}

.brands {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.brands__ele {
  width: 40%;
  height: calc((100vw - 40px) * 0.4 * 1.35);
  max-height: 540px;
  position: relative;
  // TODO: 他ブラウザで検証が必要

  &:nth-child(n + 3) {
    margin-top: 195px;

    @media only screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  a:hover {
    .brands__text {
      opacity: 0;
    }

    .brands__img img {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: calc((100vw - 40px) * 1.35);
    max-height: auto;
    margin-bottom: 55px;
  }
}

.brands__head {
  font-size: 40px;
  margin-bottom: 32px;

  @media only screen and (max-width: 767px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.brands__text {
  font-family: 'Garamond Roman';
  font-size: 18px;
  line-height: 1.3;
  color: #a5a5a5;
  width: 310px;
  position: relative;
  z-index: 4;
  transition: .3s ease-out;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.brands__img {
  width: 80%;
  max-width: 320px;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;

  img {
    width: 100%;
    transition: .3s ease-out;
  }

  @media only screen and (max-width: 767px) {
    width: 71%;
  }
}
